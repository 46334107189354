import React, { ReactElement, useState } from "react";
import { FormikProps, useFormikContext } from "formik";
import { LabeledSelectInput, LabeledRadioInput, LabeledTextInput, hasValue, TooltipWrap } from "adviesbox-shared";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { leningdeelKeuzeModalSchema, leningdeelKeuzeVoorstelModalSchema } from "../infra/hypotheek-schema";
import { MaatschappijNaamType, mapHypotheekSelectie, getProductnaam, isAankoop } from "./hypotheek-selectie-helper";

import LabeledAflossingProductKeuzelijst from "./aflossing-product-keuzelijst/LabeledAflossingProductKeuzelijst";
import { HypotheeklabelMetHypotheekoptiesOutput } from "../../.generated/producten/productentypes";
import { arrayOf, shape, string } from "prop-types";
import HypotheekSelectieGenereerSamenstelling from "./hypotheek-selectie-genereer-samenstelling";
import HypotheekSelectieWijzigSamenstelling from "./hypotheek-selectie-wijzig-samenstelling";
import { WijzigGeldverstrekkerInputLeningdeel, AflossingsVormType } from "../../.generated/forms/formstypes";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import Modal from "../../shared/components/modal/Modal";
import {
  HypotheekProductSelectieStateType,
  HypothekenState,
  HypotheekType,
  HypotheekProductSelectieVoorstelStateType
} from "../infra/hypotheek-types";
import { getHypotheekTextResources } from "../infra/hypotheek-resources";

export type HypotheekSelectieModalState = {
  data: HypotheekProductSelectieStateType | HypotheekProductSelectieVoorstelStateType;
  labels: HypotheeklabelMetHypotheekoptiesOutput;
  maatschappijNamen: MaatschappijNaamType[];
};

export type HypotheekSelectieModalProps = {
  isRestschuldHypotheek?: boolean;
  situatie: SituatieSoort;
  onSave: (verzekeringSelectie: HypotheekProductSelectieStateType | HypotheekProductSelectieVoorstelStateType) => void;
  closeModal?: () => void;
  setHypotheekSamenstellingWijzigingState: (values: any) => void;
  selectedRow?: number;
  updateLeningdeel?: boolean;
  productOnly?: boolean;
  omzetten?: boolean;
  geldverstrekkerOnly?: boolean;
};

const HypotheekSelectieModal = ({
  data,
  maatschappijNamen,
  onSave,
  labels,
  closeModal,
  situatie,
  isRestschuldHypotheek,
  setHypotheekSamenstellingWijzigingState,
  selectedRow,
  updateLeningdeel,
  omzetten = false,
  productOnly,
  geldverstrekkerOnly
}: HypotheekSelectieModalProps & HypotheekSelectieModalState): ReactElement => {
  const formik = useFormikContext<HypothekenState>();
  const [hypotheekSamenstellingLoadingState, setHypotheekSamenstellingLoadingState] = useState(false);
  const [showGenereer, setShowGenereer] = useState(false);
  const [showSamenstelling, setShowSamenstelling] = useState(false);
  const [showKeuzeProduct, setShowKeuzeProduct] = useState(false);

  const maatschappijFieldName = "maatschappijProductCode";
  const productFieldname = "productCode";
  const getToevoegenTitle = (situatie: SituatieSoort): string => {
    if (situatie === "huidig") {
      return "Selecteer soort leningdeel";
    } else {
      return showGenereer || showSamenstelling
        ? "Aflossingsvorm"
        : showKeuzeProduct
        ? "Wijziging Maatschappij"
        : "Geldverstrekker en Arrangement";
    }
  };

  const bodySelecteer = (
    props: FormikProps<HypotheekProductSelectieStateType | HypotheekProductSelectieVoorstelStateType>,
    maatschappijNaam: MaatschappijNaamType | null
  ): ReactElement => {
    const { values, setFieldValue } = props;
    const { maatschappijCode, labelCode } = values.maatschappijProductCode;
    return (
      <>
        {situatie === "voorstel" && productOnly && (
          <LabeledTextInput
            caption="Geldverstrekker"
            name={`${maatschappijFieldName}.maatschappijOmschrijving`}
            readonly={true}
          />
        )}

        {((situatie === "voorstel" && !productOnly) || geldverstrekkerOnly) && (
          <>
            <LabeledSelectInput
              readonly={productOnly}
              fieldSize="xxl"
              options={mapHypotheekSelectie(labels)}
              name={`${maatschappijFieldName}.maatschappijLabelCode`}
              appendChildren={
                !values.hasProducten && (
                  <TooltipWrap
                    name="rente-percentage"
                    warningText={getHypotheekTextResources("geenProductenWaarschuwing")}
                    placement="bottom"
                    iconType="waarschuwing"
                    tooltipClasses="px-1 mt-1"
                 />
                )
              }
              caption="Geldverstrekker"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                const maatschappijSelected = e.target.value.split("_")[0] || /* istanbul ignore next */ "";
                const labelCodeSelected = parseInt(e.target.value.split("_")[1]);
                const maatschappijNaam = maatschappijNamen.find(c => c.code === maatschappijSelected);

                // Maak het geselecteerde product leeg (bijbehorende component zorgt zelf dat dan eerste option wordt geselecteerd).
                if (values.leningdeelKeuze === "keuzeproduct") {
                  setFieldValue(`${productFieldname}.code`, null);
                }

                const labelNaam = getProductnaam(labels, maatschappijSelected, labelCodeSelected);
                setFieldValue(`${maatschappijFieldName}`, {
                  maatschappijLabelCode: e.target.value,
                  labelCode: labelCodeSelected,
                  labelOmschrijving: labelNaam,
                  maatschappijCode: maatschappijSelected || "",
                  maatschappijOmschrijving: maatschappijNaam ? maatschappijNaam.naam : /* istanbul ignore next */ ""
                });
              }}
            />
            {!productOnly && !geldverstrekkerOnly && (
              <div className="py-2">
                <LabeledRadioInput
                  caption="Arrangement"
                  fieldSize="xxl"
                  options={[
                    {
                      label: `Genereer een hypotheeksamenstelling`,
                      value: "genereer"
                    },
                    { label: "Wijzig geldverstrekker met behoud van leningdelen", value: "samenstelling" },
                    {
                      label: `Kies een product`,
                      value: "keuzeproduct"
                    }
                  ]
                    .filter(
                      c =>
                        (c.value === "samenstelling" &&
                          formik.values.producten &&
                          formik.values.producten.length > 0) ||
                        c.value !== "samenstelling"
                    )
                    .filter(f => f.value !== "genereer" || omzetten === false)
                    .filter(f => f.value !== "samenstelling" || omzetten === false)}
                  name="leningdeelKeuze"
                />
              </div>
            )}
          </>
        )}
        {values.leningdeelKeuze === "keuzeproduct" && !geldverstrekkerOnly && (
          <LabeledAflossingProductKeuzelijst
            fieldSize="xxl"
            name={productFieldname}
            caption={situatie === "huidig" ? "Hypotheekvorm" : "Product"}
            situatie={situatie}
            isAankoop={isAankoop(values.soortFinanciering)}
            maatschappijCode={maatschappijCode}
            labelCode={`${labelCode}`}
            isRestschuldHypotheek={isRestschuldHypotheek}
          />
        )}
      </>
    );
  };

  const body = (
    props: FormikProps<HypotheekProductSelectieStateType | HypotheekProductSelectieVoorstelStateType>,
    leningdelen: any,
    setHypotheekSamenstellingLoadingState: (value: boolean) => void,
    setHypotheekSamenstellingWijzigingState: (value: any) => void
  ): ReactElement => {
    const { values } = props;

    const maatschappijNaam = maatschappijNamen.find(c => {
      return c.code === values.maatschappijProductCode.maatschappijLabelCode.split("_")[0];
    });

    return (
      <>
        {!showGenereer && !showSamenstelling && !showKeuzeProduct && bodySelecteer(props, maatschappijNaam || null)}
        {values.leningdeelKeuze === "samenstelling" &&
          values.maatschappijProductCode.maatschappijCode &&
          leningdelen &&
          showSamenstelling && (
            <HypotheekSelectieWijzigSamenstelling
              situatie={situatie}
              leningdelen={formik.values.producten.map(
                (c: HypotheekType): WijzigGeldverstrekkerInputLeningdeel => {
                  return {
                    volgnummer: c.volgnummer,
                    aflossingsvorm: AflossingsVormType[c.hypotheekVorm?.aflossingsvorm]
                  };
                }
              )}
              setHypotheekSamenstellingLoadingState={setHypotheekSamenstellingLoadingState}
              setHypotheekSamenstellingWijzigingState={setHypotheekSamenstellingWijzigingState}
            />
          )}
        {values.leningdeelKeuze === "keuzeproduct" &&
          values.maatschappijProductCode.maatschappijCode &&
          leningdelen &&
          showKeuzeProduct && (
            <>
              <p className="px-1">
                Door deze wijziging dient u de leningdelen opnieuw in te brengen. Weet u zeker dat u dit wenst?
              </p>
            </>
          )}
        {values.leningdeelKeuze === "genereer" && values.maatschappijProductCode.maatschappijCode && showGenereer && (
          <HypotheekSelectieGenereerSamenstelling
            situatie={situatie}
            setHypotheekSamenstellingLoadingState={setHypotheekSamenstellingLoadingState}
          />
        )}
      </>
    );
  };

  return (
    <AdviesBoxFormik<HypotheekProductSelectieStateType | HypotheekProductSelectieVoorstelStateType>
      initialValues={data}
      validationSchema={situatie === "huidig" ? leningdeelKeuzeModalSchema : leningdeelKeuzeVoorstelModalSchema}
      onSave={onSave}
      closeModal={closeModal}
      render={(
        props: FormikProps<HypotheekProductSelectieStateType | HypotheekProductSelectieVoorstelStateType>
      ): ReactElement => {
        const { values } = props;

        const geenPartijen = formik.values.producten.length < 1;

        const partijAfwijkend =
          !geenPartijen &&
          hasValue(selectedRow) &&
          !formik.values.producten[selectedRow].product.doorlopend &&
          values.maatschappijProductCode.maatschappijCode !== formik.values.producten[selectedRow].partijCode;

        const partijAfwijkendDoorlopend =
          !geenPartijen &&
          hasValue(selectedRow) &&
          formik.values.producten[selectedRow].product.doorlopend &&
          values.maatschappijProductCode.maatschappijCode !==
            formik.values.producten[selectedRow].product.partijCodeSelectie;

        return (
          <>
            <Modal
              title={getToevoegenTitle(situatie)}
              submitDisabled={!values.hasProducten}
              body={body(
                props,
                formik.values.producten,
                setHypotheekSamenstellingLoadingState,
                setHypotheekSamenstellingWijzigingState
              )}
              onSubmitClick={() => {
                if (values.leningdeelKeuze === "genereer" && !showGenereer) {
                  setShowGenereer(true);
                } else if (values.leningdeelKeuze === "samenstelling" && !showSamenstelling) {
                  setShowSamenstelling(true);
                } else if (
                  values.leningdeelKeuze === "keuzeproduct" &&
                  !showKeuzeProduct &&
                  updateLeningdeel &&
                  (partijAfwijkend || partijAfwijkendDoorlopend)
                ) {
                  setShowKeuzeProduct(true);
                } else {
                  return props.submitForm();
                }
              }}
              onCancelClick={closeModal}
              invalid={hypotheekSamenstellingLoadingState}
            />
          </>
        );
      }}
    />
  );
};

HypotheekSelectieModal.propTypes = {
  maatschappijNamen: arrayOf(
    shape({
      code: string.isRequired,
      naam: string.isRequired
    })
  )
};

HypotheekSelectieModal.displayName = "HypotheekSelectieModal";

export default HypotheekSelectieModal;
