import * as Yup from "yup";
import { InferType } from "yup";
import { FinancieringOptions, GebruikPandSoort, Verkoopstatus } from "../../.generated/forms/formstypes";
import { onderpandSchema } from "../../shared/generic-parts/onderpand/schema";
import { adresSchema } from "../../shared/generic-parts/adres/schema";

import { getWoningTextResources } from "./woning-resources";
import { yupEnum } from "../../shared/utils/yup-enum";
import { WithSaveData } from "../../shared/utils/save-data";
import { nullableLocalDateSchema } from "adviesbox-shared";

export const HdnAnders = 99;

export enum GebruikPandSoortUi {
  Geen = "Geen",
  PrimaireWoning = "Primair",
  TweedeWoning = "Tweede woning",
  DeelsVerhuurd = "Deels verhuurd",
  GeheelVerhuurd = "Geheel verhuurd"
}

export enum SoortFoto {
  Geen = "Geen",
  Eigen = "Eigen",
  GoogleMaps = "GoogleMaps"
}

export const aankoopWoningSchema = Yup.object()
  .nullable()
  .shape({
    adres: adresSchema,
    woonAdres: adresSchema,
    energieLabel: Yup.string()
      .nullable()
      .default(null),
    adresGelijkAanWoonadres: Yup.boolean().default(false),
    onderpand: onderpandSchema,
    soortFoto: yupEnum(SoortFoto)
      .test({
        message: getWoningTextResources("ErrorFotoGebruikRapportage"),
        test: function (value): boolean {
          return this.parent.fotoGebruikRapportage ? value !== SoortFoto.Geen : true;
        }
      })
      .default(SoortFoto.Geen),
    fotoGebruikRapportage: Yup.boolean().default(false),
    locatieFoto: Yup.string()
      .nullable()
      .default(null),
    energieklasseDatum: nullableLocalDateSchema
  });

export type AankoopWoningType = InferType<typeof aankoopWoningSchema>;

export const huidigeWoningSchema = Yup.object().shape({
  volgNummer: Yup.number().default(0),
  pandId: Yup.string().default(""),
  marktwaardeBedrag: Yup.number()
    .nullable()
    .default(null),
  adres: adresSchema
});

export type HuidigeWoningType = InferType<typeof huidigeWoningSchema>;

export const verkoopWoningSchema = Yup.object().shape({
  volgNummer: Yup.number().default(0),
  pandId: Yup.string().default(""),
  gebruik: yupEnum(GebruikPandSoort).default(GebruikPandSoort.Geen),
  gebruikPandVoorstel: yupEnum(GebruikPandSoort).default(GebruikPandSoort.Geen),
  verkoopstatus: yupEnum(Verkoopstatus).default(Verkoopstatus.WordtVerkocht),
  verkoopstatusDatum: nullableLocalDateSchema,
  ontbindendeVoorwaardenDatum: nullableLocalDateSchema,
  verkoopprijs: Yup.number()
    .nullable()
    .test("is-required", "Verkoopprijs is verplicht.", function (value) {
      const { verkoopstatus } = this.parent;
      if (verkoopstatus === Verkoopstatus.IsVerkocht || verkoopstatus === Verkoopstatus.WordtVerkocht) {
        return value !== null && value !== undefined;
      }
      return true;
    })
    .default(null),
  berekenen: Yup.boolean().default(false),
  verkoopkosten: Yup.number()
    .nullable()
    .default(null),
  marktwaarde: Yup.number()
    .nullable()
    .default(null),
  verkoopondervoorwaarden: Yup.boolean()
    .nullable()
    .default(false),
  constructie: Yup.string()
    .nullable()
    .default(null),
  erfpachtConstructieAnders: Yup.string()
    .nullable()
    .default(null),
  constructieOmschrijving: Yup.string()
    .max(100, "De maximale toegestane lengte is 100 karakters.")
    .test("constructieOmschrijving", "Verplicht veld", function (this: Yup.TestContext, input: string): boolean {
      if (this.parent.constructie === `${HdnAnders}`) {
        return !!input && input.trim().length > 0;
      }

      return true;
    })
    .nullable()
    .default(null),
  adres: adresSchema
});

export type VerkoopWoningType = InferType<typeof verkoopWoningSchema>;

export const woningSchema = Yup.object().shape({
  aanTeKopenWoning: aankoopWoningSchema,
  teVerkopenWoningen: Yup.array(verkoopWoningSchema).default([]),
  huidigeWoningen: Yup.array(huidigeWoningSchema).default([]),
  hypotheekOpWoning: Yup.string()
    .nullable()
    .default(null),
  financiering: yupEnum(FinancieringOptions).default(FinancieringOptions.AankoopBestaandeBouw)
});

export type WoningProps = InferType<typeof woningSchema> & WithSaveData<WoningState>;
export type WoningState = InferType<typeof woningSchema>;
