/* istanbul ignore file */
import { Garantiesoort, GebruikPandSoort, AflossingsVormType } from "../../.generated/forms/formstypes";
import { HypotheeklabelMetHypotheekopties } from "../../.generated/producten/productentypes";
import { partijAnders, partijOnafhankelijk } from "../../producten-overzicht/infra/product-constanten";
import { bepaalFiscaleGegevensEinddatum } from "../../producten-overzicht/infra/producten-helper";
import { premieGegevensSchema } from "../../producten-overzicht/infra/producten-overzicht-schema";
import { SituatieSoort } from "../../producten-overzicht/infra/producten-overzicht-types";
import { AanvragerKeuze, mapBerekenInput } from "../../shared/types";
import { addMonths, addYears } from "../../shared/utils/dates";
import { SoortOpnamesUitkering } from "../opnames/opnames-types";
import { hypotheekFiscaleRegelingSchema, hypotheekSchema, kapitaalopbouwSchema } from "./hypotheek-schema";
import {
  HypotheekOptiesType,
  HypotheekType,
  HypothekenState,
  LeningdeelgegevensType,
  PandType
} from "./hypotheek-types";
import {
  calculateBelastingVerhouding,
  getPandRestant,
  getRestschuld,
  getLeningdeelSom,
  getAfrekbaarBedrag
} from "./hypotheek-utils";

export const mapProductSelectieToHypotheekProduct = (
  maatschappijCode: string,
  maatschappijNaam: string,
  labelCode: number,
  productCode: string,
  aflosVorm: AflossingsVormType,
  productNaam: string,
  hypotheek: HypothekenState,
  situatie: SituatieSoort,
  labelOmschrijving: string,
  renteBoxMaatschappijCode: string | null,
  renteboxCode: number | null
): HypotheekType => {
  let leningdeel = hypotheekSchema.default();
  if (situatie === "voorstel") {
    leningdeel.hypotheekProductDetails = null;
  }

  // NB: er wordt geen rekening met productkenmerken gehouden bij het aanmaken of wijzigen van producten
  // pas na het overnemen in het scherm zijn deze van toepassing en worden ze opgehaald.

  if (aflosVorm === "Spaarrekening") {
    leningdeel.fiscaleRegeling = hypotheekFiscaleRegelingSchema.default();
    leningdeel.premieGegevens = premieGegevensSchema.default();
    leningdeel.kapitaalopbouw = kapitaalopbouwSchema.default();
  }

  if (aflosVorm === "Aflosvrij"){
    leningdeel.leningdeelgegevens.betreftAflossingsvrij = true;
  }

  const fiscalegegevens = leningdeel.fiscalegegevens;
  const hypotheekProductDetails = leningdeel.hypotheekProductDetails;
  const leningdeelgegevens = leningdeel.leningdeelgegevens;

  // TODO: ingangsdatum en einddatum moeten afhankelijk zijn van passeerdatum van vergoedingenoverzicht. Issue 45575
  const ingangsdatum =
    situatie === "huidig" && hypotheek.producten.length > 0
      ? hypotheek.producten[0].product.ingangsdatum
      : hypotheek.aanvangsdatum;

  const gelinktePand = getPandRestant(hypotheek.panden, hypotheek.producten, situatie);
  const isTweedeWoning = gelinktePand.gelinktePand.gebruikPand === GebruikPandSoort.TweedeWoning;
  const gelinktePandMetBewoners = hypotheek.panden.find(p => gelinktePand.gelinktePand.pandId === p.pandId);

  const volgnummers = hypotheek.producten.map(c => c.volgnummer);
  leningdeel.volgnummer = volgnummers.length > 0 ? ++volgnummers.sort().reverse()[0] : 1;
  leningdeel.renteBoxMaatschappijCode = renteBoxMaatschappijCode;
  leningdeel.isNieuw = true;
  leningdeel.nearMatchHypotheekvormen = null;
  leningdeel.product.partijNaam = maatschappijNaam;
  leningdeel.labelNaam = labelOmschrijving;
  leningdeel.partijCode = situatie === "huidig" ? partijOnafhankelijk : maatschappijCode;
  leningdeel.productCode = productCode;
  leningdeel.soortOnderpand = hypotheek.soortFinanciering;
  leningdeel.product.wijzigingenInDoorlopendProductOvernemen = situatie === "huidig" ? true : null;
  leningdeel.product.ingangsdatum = ingangsdatum;
  leningdeel.schuldenaars.schuldenaar = getSchuldenaars(situatie, leningdeel, gelinktePandMetBewoners);
  leningdeel.product.einddatum = ingangsdatum
    ? addMonths(
        addYears(ingangsdatum, leningdeel.product.looptijd.jaren || 0),
        leningdeel.product.looptijd.maanden || 0
      )
    : null;
  leningdeel.product.renteboxCode = renteboxCode;

  if (ingangsdatum)
    leningdeel.leningdeelgegevens.einddatum = addYears(
      ingangsdatum,
      Number(leningdeel.leningdeelgegevens.rentevastPeriodeJaar) || 0
    );

  leningdeel.product.partijCodeSelectie =
    maatschappijCode === partijOnafhankelijk && situatie !== "voorstel" ? partijAnders : maatschappijCode;
  leningdeel.product.uwBemiddeling = situatie === "voorstel";
  leningdeel.product.productNummer = productCode;
  leningdeel.labelCode = labelCode;
  leningdeel.hypotheekVorm.code = parseInt(productCode);
  leningdeel.hypotheekVorm.omschrijving = productNaam;
  leningdeel.hypotheekVorm.aflossingsvorm = aflosVorm;

  // Gewenste hypotheekbedrag in voorstel het op Financieringsbehoefte bepaalde bedrag, in huidige situatie gelijk aan totale hypotheek op pand.
  const gewensteHypotheekBedrag =
    situatie === "voorstel" ? hypotheek.gewensteHypotheek : gelinktePand.gelinktePand.totaleHypotheekBedrag;

  // Restschuld leningdelen worden in backend aangemaakt (voor restant/rest schuld bij verkoop woning).
  const totaalRestschuld = getRestschuld(hypotheek.producten);

  const opgeteldeLeningdeelBedrag = (gelinktePand.opgeteldeLeningdrag || 0) + totaalRestschuld || null;

  const leningdeelSom = getLeningdeelSom(
    situatie,
    gewensteHypotheekBedrag,
    opgeteldeLeningdeelBedrag,
    totaalRestschuld,
    gelinktePand,
    hypotheek.producten,
    maatschappijCode
  );

  leningdeel.opnameSoort =
    leningdeel.hypotheekVorm.aflossingsvorm === AflossingsVormType.KredietNoPay ? SoortOpnamesUitkering.Eenmalig : null;

  if (hypotheekProductDetails) {
    hypotheekProductDetails.hypotheekOpWoning = gelinktePand.gelinktePand.pandId;
    leningdeelgegevens.oorspronkelijkeHoofdsom = leningdeelSom;
  }

  leningdeelgegevens.leningdeelHoofdsom = mapBerekenInput(leningdeelSom, true, leningdeelSom);
  leningdeelgegevens.rentePercentage = { bedrag: null, berekendBedrag: null, berekenen: situatie === "voorstel" };
  leningdeelgegevens.garantie = situatie === "huidig" ? Garantiesoort.Geen : null;

  const leningbedrag = leningdeelgegevens.leningdeelHoofdsom.bedrag || 0;

  const aftrekbaarBedrag = hypotheek.eigenwoningschuldBedrag
    ? getAfrekbaarBedrag(hypotheek.eigenwoningschuldBedrag, hypotheek.producten, maatschappijCode)
    : situatie === "huidig"
    ? leningbedrag
    : 0;

  const { box1Bedrag, box3Bedrag, box1Percentage, box3Percentage } = calculateBelastingVerhouding(
    isTweedeWoning,
    aftrekbaarBedrag,
    leningbedrag
  );

  fiscalegegevens.deelBox1Bedrag = box1Bedrag;
  fiscalegegevens.deelBox3Bedrag = box3Bedrag;
  fiscalegegevens.deelBox1Percentage = box1Percentage;
  fiscalegegevens.deelBox3Percentage = box3Percentage;

  if (leningdeel.hypotheekVorm.aflossingsvorm === AflossingsVormType.KredietNoPay) {
    leningdeel.product.betreftOpname = true;
    leningdeel.fiscalegegevens.bevatOpnames = true;

    // alles in box3
    fiscalegegevens.deelBox3Bedrag += fiscalegegevens.deelBox1Bedrag;
    fiscalegegevens.deelBox3Percentage += fiscalegegevens.deelBox1Percentage;

    fiscalegegevens.deelBox1Bedrag = 0;
    fiscalegegevens.deelBox1Percentage = 0;
  }

  //Bepaal mogelijkheid voor renteaftrek (alleen box-1 is aftrekbaar) en vul specificatie
  if (fiscalegegevens.deelBox1Bedrag && fiscalegegevens.deelBox1Bedrag > 0) {
    const einddatum = ingangsdatum ? bepaalFiscaleGegevensEinddatum(ingangsdatum) : null;
    fiscalegegevens.renteaftrekSpecificatie = {
      renteAftrekken: [
        {
          aanvangsdatum: ingangsdatum,
          einddatum: einddatum,
          bedrag: fiscalegegevens.deelBox1Bedrag
        }
      ]
    };
    fiscalegegevens.begindatumRenteaftrek = ingangsdatum;
    fiscalegegevens.einddatumRenteaftrek = einddatum;
  }

  // Spaar hypotheek: bepaalde de default verzekerde en vul kapitaal met leningbedrag
  if (leningdeel.hypotheekVorm.aflossingsvorm === AflossingsVormType.Spaarrekening) {
    leningdeel = getSpaarrekeningDefaults(leningdeel, leningbedrag, leningdeelgegevens);
  }

  return leningdeel;
};

export function getSchuldenaars(
  situatie: SituatieSoort,
  leningdeel: HypotheekType,
  gelinktePandMetBewoners?: PandType
): AanvragerKeuze {
  return situatie === "huidig" && gelinktePandMetBewoners
    ? (gelinktePandMetBewoners.bewoners as AanvragerKeuze)
    : leningdeel.schuldenaars.schuldenaar;
}

export function getSpaarrekeningDefaults(
  leningdeel: HypotheekType,
  leningbedrag: number,
  leningdeelgegevens: LeningdeelgegevensType
): HypotheekType {
  leningdeel.verzekerde.verzekerde = AanvragerKeuze.Aanvrager1;
  leningdeel.verzekeringnemers.verzekeringnemer = AanvragerKeuze.Aanvrager1;
  if (leningdeel.kapitaalopbouw) {
    leningdeel.kapitaalopbouw.doelkapitaal1Bedrag = leningbedrag || null;
    leningdeel.kapitaalopbouw.voorbeeldkapitaal1Percentage = leningdeelgegevens.rentePercentage?.bedrag ?? null;
  }
  if (leningdeel.premieGegevens) {
    leningdeel.premieGegevens.looptijd = leningdeel.product.looptijd;
  }

  return leningdeel;
}

export function mapHypotheekOptiesSelectie(
  maatschappijCode: string,
  labelCode: number,
  hypotheekLabelOptiesDl: {
    [key: string]: HypotheeklabelMetHypotheekopties;
  } | null
): HypotheekOptiesType[] {
  if (hypotheekLabelOptiesDl) {
    const hypotheekOptiesUi: HypotheekOptiesType[] = [];
    const hypotheekOptiesDl = Object.keys(hypotheekLabelOptiesDl)
      .map(c => hypotheekLabelOptiesDl[c])
      .filter(d => d.maatschappijCode === maatschappijCode)
      .find(c => c.code === labelCode)?.hypotheekopties;
    hypotheekOptiesDl?.map(c => {
      hypotheekOptiesUi.push({
        code: c.code || 0,
        geselecteerd: c.default || true,
        omschrijving: c.omschrijving,
        toelichting: c.toelichting,
        rentekortingPercentage: c.rentekortingPercentage,
        maatschappijCode: maatschappijCode,
        hypotheekLabelCode: labelCode,
        verplicht: c.verplicht,
        default: c.default
      });
    });
    return hypotheekOptiesUi;
  }
  return [];
}
